import React, { useMemo, useState } from "react"
import getValue from "get-value"
import Section from "@components/section"
import SEO from "../components/seo"
import BlogList from "../components/blog-list"
import PageHeader from "../components/page-header"
import Pagination from "@components/pagination/index"
import InstagramFeed from "@components/instagram-feed"
import { useSiteSettingsStore } from "@src/hooks/useStore"

const BlogIndex = ({ pageContext }) => {
  const document = getValue(pageContext, "pageEntry")
  const categories = pageContext.categories
  const entries = pageContext.entries
  const { pageIntro, title: pageTitle, mainImage } = pageContext.pageEntry[0]

  const { siteSettings } = useSiteSettingsStore(['siteSettings']);

  const instaPosts = siteSettings?.instaPosts;

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(9)

  const totalPages = Math.ceil(entries.length / itemsPerPage)

  if ((currentPage - 1) * itemsPerPage > entries.length) {
    setCurrentPage(totalPages)
  }

  const blogItemsToDisplay = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + +itemsPerPage

    return entries?.slice(startIndex, endIndex);
  }, [currentPage]);

  const onPageChange = newPage => {
    setCurrentPage(newPage)
  }

  const {
    activeCategorySlug,
  } = pageContext

  const documentData = {...document, blogCategory: activeCategorySlug}

  const subnavItems = [    
    {
      title: "All",
      url: `/blog/`,
      key: `categoryLink-all`,
    },
    ...categories.map(category => {
      return {
        ...category,
        url: `/blog/${category.slug.current}/`,
        key: `categoryLink-${category._id}`,
      }
    }),
  ]

  return (
    <>
      <SEO {...{ document: documentData }} />
      <div className="mb-12">
        <PageHeader center title={pageTitle} intro={pageIntro} bgImage={mainImage} bgColor={'bg-[#F9F9F9]'} height='h-[325px]' items={subnavItems} activeSlug={activeCategorySlug}/>
      </div>

      <Section settings={{ classname: '!mt-10' }}>
        <div className="flex flex-col justify-center items-center w-full overflow-hidden">
            <BlogList entries={blogItemsToDisplay} />
            <Pagination 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
            <InstagramFeed  instaPosts={instaPosts}/>
        </div>
      </Section>
    </>
  )
}

export default BlogIndex
